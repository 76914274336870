import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {icons} from './assets/icons'
import {Provider} from 'react-redux'
import axios from 'axios';
import store from './store'
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {S3_Pool_conf} from "./config/aws-amplify";
import Amplify from 'aws-amplify';
import config from "./config/base_location.json";
toast.configure()

React.icons = icons
axios.defaults.baseURL = config.api_base_url;
axios.defaults.headers.post['Content-Type'] = 'application/json';
const BASE_URL = axios.defaults.baseURL

Amplify.configure(S3_Pool_conf);

axios.interceptors.request.use(request => {
    // Edit request config
    const idToken = localStorage.getItem('idToken');
    request.headers['Authorization'] = `${idToken}`;
    return request;
}, error => {
    return Promise.reject(error);
});


axios.interceptors.response.use(response => {
    // Edit request config
    return response;
}, error => {
    if (error.response.status !== 401) {
        return Promise.reject(error);
    }

    const refreshToken = localStorage.getItem('refreshToken');

    let refreshTokenExpired = true;
    return axios.post(`${BASE_URL}auth/authentication/refresh_token`,
        JSON.stringify({
            "refresh_token": refreshToken
        })).then(response => {
        localStorage.setItem('accessToken', response.data.data.accessToken)
        localStorage.setItem('idToken', response.data.data.idToken)
        error.response.config.headers['Authorization'] = `${response.data.data.idToken}`;
        refreshTokenExpired = false;
        return axios(error.response.config)
    }).catch(error => {
        if (refreshTokenExpired) {
            localStorage.clear();
            document.cookie.split(";").forEach(function (c) {
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });

            window.location = '/login';
        }else {
            return Promise.reject(error);
        }
    })
});


ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,

    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


export {
    BASE_URL
}
