import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import './scss/style.scss';
import requireLogin from './authUtils/RouterControl';


const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"/>
    </div>
)


const TheLayout = React.lazy(() => import('./containers/TheLayout'));


const Login = React.lazy(() => import('./views/pages/Login'));
const Register = React.lazy(() => import('./views/pages/Register'));
const Forgetpassword = React.lazy(() => import('./views/pages/Forgetpassword'));
// const Resetpassword = React.lazy(() => import('./views/pages/Resetpassword'));
const ResetPassword = React.lazy(() => import('./views/pages/ResetPassword/resetPassword'));
const ConfirmationCode = React.lazy(() => import('./views/pages/ConfirmationCode'));
// const ConfirmSignup = React.lazy(() => import('./views/pages/ConfirmSignup'));
const Welcome = React.lazy(() => import('./views/pages/Welcome'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const AdminChangeForcePass = React.lazy(() => import('./views/userAdmin/ChangeAdminAdd'));



class App extends Component {

    render() {
        return (

            <BrowserRouter>

                <React.Suspense fallback={loading}>
                    <GuardProvider guards={[requireLogin]} /*loading={Loading}*/ error={<h1>Not allowed</h1>}>
                        <Switch>
                            <GuardedRoute
                                exact path="/login"
                                name="Login Page"
                                component={Login}
                                meta={{ auth: false }}
                            />
                            <GuardedRoute
                                exact path="/signin/"
                                name="Login Page"
                                component={Login}
                                meta={{ auth: false }}
                            />
                            <GuardedRoute
                                exact path="/confirm/:confirmationCode"
                                name="Confirm Code Page"
                                component={Welcome}
                                meta={{ auth: false }}
                            />
                            <GuardedRoute
                                exact path="/confirmcode"
                                name="Confirm Page"
                                component={ConfirmationCode}
                                meta={{ auth: false }}
                            />
                            <GuardedRoute
                                exact path="/forgetpassword"
                                name="Forgetpassword Page"
                                component={Forgetpassword}
                                meta={{ auth: false }}
                            />
                            <GuardedRoute
                                exact path="/forcepasschange"
                                name="Force Password Change"
                                component={AdminChangeForcePass}
                                meta={{ auth: false }}
                            />
                            <GuardedRoute
                                exact path="/resetpassword"
                                name="Resetpassword Page"
                                component={ResetPassword}
                                meta={{ auth: false }}
                            />
                            <GuardedRoute
                                exact path="/reset-password"
                                name="Resetpassword Page"
                                component={ResetPassword}
                                meta={{ auth: false }}
                            />
                            <GuardedRoute
                                exact path="/register"
                                name="Register Page"
                                component={Register}
                                meta={{ auth: false }}
                            />






                            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
                        </Switch>
                    </GuardProvider>
                </React.Suspense>

            </BrowserRouter>
        );
    }
}

export default App;
