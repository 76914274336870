import Storage from "@aws-amplify/storage";
const baseLocation = require('./base_location');

export const S3_Pool_conf = {
    Auth: {
        identityPoolId: baseLocation.identityPoolId,
        region: baseLocation.region,
        userPoolId: baseLocation.userPoolId,
        userPoolWebClientId: baseLocation.userPoolWebClientId,
    },
    Storage: {
        AWSS3: {
            bucket: baseLocation.bucket,
            region: baseLocation.region,
        }
    }
};

export function SetS3Config(bucket, level) {
    Storage.configure({
        bucket: bucket,
        level: level,
        region: baseLocation.region,
        identityPoolId: baseLocation.identityPoolId
    });
}

