const requireLogin = (to, from, next) => {

    const getIsLoggedIn = () => {
        // return true;
        console.log("router here")
        const userData = localStorage.getItem('userId');
        console.log("router", !!userData)
        return !!userData;
    }
    if (to.meta.auth) {

        if (getIsLoggedIn()) {
            next();
        }
        else {
            next.redirect('/login');
        }
    }
    else {
        if (getIsLoggedIn()) {
            next.redirect('/');
        }
        else {
            next();
        }
    }
};

export default requireLogin;
